import cx from "classnames";
import React from "react";
import useDarkMode from "use-dark-mode";
import css from "./DarkModeToggleButton.module.css";

export default function DarkModeToggleButton(props: { className?: string }) {
  const { value: isDark, toggle } = useDarkMode();
  return (
    <button onClick={toggle} className={cx(css.button, props.className)}>
      Make it {isDark ? "light" : "dark"}
    </button>
  );
}
