import React, { ReactNode } from "react";
import DarkModeToggleButton from "../DarkModeToggleButton";
import "../global.css";
import css from "./Layout.module.css";

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <div>
      <div className={css.darkModeButton}>
        <DarkModeToggleButton />
      </div>
      <main className={css.main}>{children}</main>
    </div>
  );
};

export default Layout;
